export const FeedbackList = [
  // {
  //   img: './Image/CustomerImage/fb-chi-phạm-300x300.jpeg',
  //   name: 'Phạm Lan Chi',
  //   description: 'Marketer, Timo Digital Bank',
  //   feedback: 'Chỉ cần báo tên mã hiệu chuyến bay, giờ mình muốn người ta gặp mình và nơi muốn người ta đưa rước. Nhanh gọn lẹ, yêu đương mà cũng như này thì tốt quá mấy má.\nTất nhiên, đã là đi đi về về sân bay thì cần đúng giờ để khách hàng không lỡ chuyến hay sấp sấp ngửa ngửa thì rõ là mình đã đạt được nguyện vọng. No late. No excuse. No cash to drivers.\nVà tất nhiên là giá cả hợp nhí hơn so với việc mình bắt taxi từ city center đi airport và ngược lại'
  // },
  // {
  //   img: './Image/CustomerImage/fb-phamthuhuong-300x300.jpeg',
  //   name: 'Phạm Thu Hương',
  //   description: 'GX Manager, Elite Fitness',
  //   feedback: 'Điều quan trọng thứ 1 là giá cả siêu tốt. Mình chuyển khoản từ đầu cho bạn bên dịch vụ xong là bạn í gửi contact rõ ràng của lái xe có sđt cả biển số xe cho mình luôn.\n Mình chưa kịp gọi thì các anh lái xe đã chủ động gọi hẹn ngày giờ đón rồi, mà còn đến sớm tận 30p đứng chờ\nĐiều quan trọng thứ 2 là các anh lái xe rất lịch sự, thơm tho, nói chuyện nhẹ nhàng, lái xe từ tốn, nhà mình có trẻ con và người bị say xe nhưng cũng rất hài lòng.'
  // },
  // {
  //   img: './Image/CustomerImage/fb-vodieulinh-300x300.jpeg',
  //   name: 'Võ Diệu Linh',
  //   description: 'Sinh viên, USTH',
  //   feedback: 'Share ngay cho anh em 1 địa chỉ gọi xe đi sân bay uy tín nhé. Mình được giới thiệu bên dịch vụ xe sân bay này và cũng vừa được trải nghiệm đợt đi Đà Lạt vừa rồi thấy quá ưng.\nThông tin xe và lái xe rõ ràng , tài xế đón đúng giờ, nhẹ nhàng tình cảm thân thiện, order nhạc theo yêu cầu.\nAnh em có nhu cầu thì gọi ngay bên này để tiết kiệm được kha khá chi phí và tránh gặp phải các bác taxi dù phi như ăn cướp, vừa lái xe vừa buôn chuyện điện thoại như ngoài quán bia nhé'
  // },
  {
    img: './Image/CustomerImage/fb-chi-phạm-300x300.jpeg',
    name: 'Phạm Lan Chi',
    description: 'Marketer, Timo Digital Bank',
    feedback: 'Chỉ cần báo tên mã hiệu chuyến bay, giờ mình muốn người ta gặp mình và nơi muốn người ta đưa rước.Nhanh gọn lẹ, yêu đương mà cũng như này thì tốt quá mấy má.\nTất nhiên, đã là đi đi về về sân bay thì cần đúng giờ để khách hàng không lỡ chuyến hay sấp sấp ngửa ngửa thì rõ là mình đã đạt được nguyện vọng. No late. No excuse. No cash to drivers.\nVà tất nhiên là giá cả hợp nhí hơn so với việc mình bắt taxi từ city center đi airport và ngược lại'
  },
  {
    img: './Image/CustomerImage/fb-phamthuhuong-300x300.jpeg',
    name: 'Phạm Thu Hương',
    description: 'GX Manager, Elite Fitness',
    feedback: 'Điều quan trọng thứ 1 là giá cả siêu tốt. Mình chuyển khoản từ đầu cho bạn bên dịch vụ xong là bạn í gửi contact rõ ràng của lái xe có sđt cả biển số xe cho mình luôn.\nMình chưa kịp gọi thì các anh lái xe đã chủ động gọi hẹn ngày giờ đón rồi, mà còn đến sớm tận 30p đứng chờ\nĐiều quan trọng thứ 2 là các anh lái xe rất lịch sự, thơm tho, nói chuyện nhẹ nhàng, lái xe từ tốn, nhà mình có trẻ con và người bị say xe nhưng cũng rất hài lòng.'
  },
  {
    img: './Image/CustomerImage/fb-vodieulinh-300x300.jpeg',
    name: 'Võ Diệu Linh',
    description: 'Sinh viên, USTH',
    feedback: 'Share ngay cho anh em 1 địa chỉ gọi xe đi sân bay uy tín nhé. Mình mới được giới thiệu bên gọi dịch vụ xe sân bay này và cũng vừa được trải nghiệm đợt đi Đà Lạt vừa rồi thấy quá ưng.\nThông tin xe và lái xe rõ ràng , tài xế đón đúng giờ, nhẹ nhàng tình cảm thân thiện, order nhạc theo yêu cầu.\nAnh em có nhu cầu thì gọi ngay bên này để tiết kiệm được kha khá chi phí và tránh gặp phải các bác taxi dù phi như ăn cướp, vừa lái xe vừa buôn chuyện điện thoại như ngoài quán bia nhé'
  },
]