import './Call.css';

export default function SaleItem(props) {
  function formatPhoneNumber(phoneNumber) {
    const cleanNum = phoneNumber.toString().replace(/\D/g, '');
    const match = cleanNum.match(/^(\d{3})(\d{0,3})(\d{0,4})$/);
    if (match) {
      return match[1] + ' ' + (match[2] ? match[2] + " " : "") + match[3];
    }
    return cleanNum;
  }

  return(
    <div className="sale-item">
      <div className="sale-info-box">
        <img 
          src={props.offline? 
            "/Image/Call/Sale-avatar-offline.png"
            :
            "/Image/Call/Sale-avatar.png"
          }
          alt="" 
          className="avatar" />
        <h1 
          className={`heading-3 ${props.offline? 'offline-color' : ''}`}
        >
          {props.name}
        </h1>
      </div>
      <div className="sale-info-box">
        <img 
          src={props.offline?
            "/Image/Call/phone-offline.png"
            :
            "/Image/Call/phone.png"
          }
          alt="" 
          className="phone-image" />
        <h1 className="heading-3">
          <a 
            className={props.offline? 'offline-color' : ''} 
            href={`tel:${props.phoneNumber}`}>
            {formatPhoneNumber(props.phoneNumber)}
          </a>
        </h1>
      </div>
    </div>
  )
}