import { useEffect, useState } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import { getDocs, collection } from 'firebase/firestore';

import './AboutUs.css';
import MemberItem from './MemberItem';
import MemberBox from './MemberBox';

export default function AboutUs() {
  const [ leadTeam, setLeadTeam ] = useState([]);
  const [ saleTeam, setSaleTeam ] = useState([]);
  const [ mobileTeam, setMobileTeam ] = useState([]);
  const [ feTeam, setFeTeam ] = useState([]);
  const [ beTeam, setBeTeam ] = useState([]);

  useEffect(() => {
    const firebaseConfig = {
      apiKey: "AIzaSyD4q-7cxKIJYeC-6hybqSiw6Q3h1kuJ3ls",
      authDomain: "pippipinfo.firebaseapp.com",
      projectId: "pippipinfo",
      storageBucket: "pippipinfo.appspot.com",
      messagingSenderId: "77565113245",
      appId: "1:77565113245:web:e1ea6a4dafca1330bcb574",
      measurementId: "G-RT0Q6E621V"
    };
    const app = firebase.initializeApp(firebaseConfig);
    const db = app.firestore();
    getDocs(collection(db, "LeadTeam")).then((teamMember) => {
      setLeadTeam([]);
      teamMember.forEach((member) => {
        // console.log(member.data());
        setLeadTeam(prev => [...prev, member.data()]);
      })
    });
    getDocs(collection(db, "SaleTeam")).then((teamMember) => {
      setSaleTeam([]);
      teamMember.forEach((member) => {
        // console.log(member.data());
        setSaleTeam(prev => [...prev, member.data()]);
      })
    });
    getDocs(collection(db, "MobileTeam")).then((teamMember) => {
      setMobileTeam([]);
      teamMember.forEach((member) => {
        // console.log(member.data());
        setMobileTeam(prev => [...prev, member.data()]);
      })
    });
    getDocs(collection(db, "FeTeam")).then((teamMember) => {
      setFeTeam([]);
      teamMember.forEach((member) => {
        // console.log(member.data());
        setFeTeam(prev => [...prev, member.data()]);
      })
    });
    getDocs(collection(db, "BeTeam")).then((teamMember) => {
      setBeTeam([]);
      teamMember.forEach((member) => {
        // console.log(member.data());
        setBeTeam(prev => [...prev, member.data()]);
      })
    });
  }, []);

  return(
    <div className="about-us">
      <div className='about-us-title component-layout'>
        <div className="about-us-wrapper component-wrapper">
          <div className='page-path'>
            <a className='text-p-big' href='/'>Home</a>
            <span className='text-p-big'>{'>'}</span>
            <a className='text-p-big' href='/about-us'>About-us</a>
          </div>
          <h1 className='about-us-title-text heading-1'>Đội ngũ Pippip</h1>
        </div>
      </div>
      <div className='member-box component-layout'>
        <div className="member-box-wrapper component-wrapper">
          {leadTeam.map((e, index) =>
            <MemberItem key={index} member={e} />
          )}
          {leadTeam.length === 1 ?
            <div className='insert-enough-480' /> : null
          }
          {leadTeam.length !== 0 && leadTeam.length < 3 ?
            <div className='insert-enough-900' /> : null
          }
        </div>
      </div>
      <MemberBox 
        boxId='cskh-member-box'
        boxClass='cskh-box'
        memberTeam={saleTeam}
        title='Team CSKH'
      />
      <MemberBox 
        boxId='mobile-app-member-box'
        boxClass='mobile-app-box'
        memberTeam={mobileTeam}
        title='Team App Mobile'
      />
      <MemberBox 
        boxId='fe-member-box'
        boxClass='fe-box'
        memberTeam={feTeam}
        title='Team Front End'
      />
      <MemberBox 
        boxId='be-member-box' 
        boxClass='be-box'
        memberTeam={beTeam}
        title='Team Back End'
      />
    </div>
  );
}