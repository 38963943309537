import './Introduction.css';

function IntroItem(props) {
  return(
    <div className='introduction-item'>
      <img src={props.img} alt=''/>
      <div className='introduction-item-content'>
        <h2 className='heading-3'>{props.subtitle}</h2>
        <p className='text-p-small'>{props.content1}</p>
        {props.content2 ? <p className='text-p-small'>{props.content2}</p> : null}
      </div>
    </div>
  );
}

export default IntroItem;