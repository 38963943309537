import './Footer.css';

function Footer() {
  const HandleOpenIntroduction = () => {
    document.getElementById('intro-content-layer').classList.add('show');
    setTimeout(() => {
      document.getElementById('introduction-content').classList.remove('hide');
    }, 500);
  }

  const HandleOpenService = () => {
    document.getElementById('service-content-layer').classList.add('show');
    setTimeout(() => {
      document.getElementById('service-content').classList.remove('hide');
    }, 500);
  }

  const HandleOpenFeedback = () => {
    document.getElementById('feedback-content-layer').classList.add('show');
    setTimeout(() => {
      document.getElementById('feedback-content').classList.remove('hide');
      document.getElementById('feedback-end-line').classList.remove('hide');
    }, 500);
  }

  return(
    <div className='footer component-layout'>
      <div className='menu-footer component-wrapper'>
        <div className='logo-name'>
          <img src='./Image/logo-pippip.png' alt='' />
          <h1>PIPPIP.VN</h1>
        </div>
        <div className='text-p-big footer-slogan'>
          Nền tảng đặt xe sân bay, du lịch nghỉ dưỡng trọn gói 
        </div>
        <div className="menu-content">
          <div className='heading-3'>
            <a href='/about-us' target='_blank' rel="noreferrer">
              Về chúng tôi
            </a>
          </div>
          <div className='text-p-big'>
            <a 
              href='/#introduction'
              onClick={HandleOpenIntroduction}
            >
              Tại sao đặt xe Pippip
            </a>
          </div>
          <div className='text-p-big'>
            <a 
              href="/#service"
              onClick={HandleOpenService}
            >
              Các dịch vụ của Pippip
            </a>
          </div>
          <div className='text-p-big'>
            <a 
              href="/#feedback"
              onClick={HandleOpenFeedback}
            >
              Khách hàng nói về Pippip
            </a>
          </div>
        </div>
        <div className='location'>
          <div className='heading-3'>Địa điểm</div>
          <div className='text-p-big'>Hà Nội</div>
          <div className="text-p-big">Đà Nẵng</div>
        </div>
        <div id='contact-in-footer' className='contact'>
          
          <div className='heading-3'>
            <a href='/call'>Liên hệ</a>
          </div>
          <div className='text-p-big'>
            <img src='/Image/mail-icon.png' alt='' />
            &nbsp;info@pippip.vn
          </div>
          <div className="text-p-big">
            <img src='/Image/zalo-icon.png' alt='' />
            <a href='tel:+84965670347'>&nbsp;096 567 0347</a>
          </div>
        </div>
      </div>
      <div className='copyright'>
        <p>Copyright © 2022 Pippip. All rights reserved.</p>
      </div>
    </div>
  );
}

export default Footer;