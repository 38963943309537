import './MobileLink.css'

function MobileLink() {
  return(
    <div id='mobile-link' className='mobile-link component-layout'>
      <div className='mobile-link-wrapper component-wrapper'>
        <img className='mobile-image' src='./Image/LinkApp/mobile-image.png' alt=''/>
        <div className='mobile-link-content'>
          <div>
            <h1 className='heading-1'>Đặt xe Pippip bằng cách tải App</h1>
            <p className='text-p-big'>Tải ứng dụng đặt xe Pippip để dễ dàng đặt xe và theo dõi lộ trình hoàn toàn tự động</p>
            <div className='link-app'>
              <a 
                href='https://play.google.com/store/apps/details?id=vn.pippip'
                target='_blank'
                rel="noreferrer"
              >
                <img src='./Image/LinkApp/AndroidLink.png' alt='' />
              </a>
              <a
                href='https://apps.apple.com/vn/app/pippip/id1660783160'
                target='_blank'
                rel="noreferrer"
              >
                <img src='./Image/LinkApp/IosLink.png' alt='' />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className='line'/>
    </div>
  );
}

export default MobileLink;