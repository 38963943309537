import { useRef, useState } from 'react';
import { Carousel } from 'antd';

import './Feedback.css'
import FeedbackItem from './FeedbackItem';
import { FeedbackList } from './FeedbackList';

function Feedback() {
  const slider = useRef(null)
  const ContentElement = useRef(null);
  const HideElement1 = useRef(null);
  const HideElement2 = useRef(null);
  const [ pageX, setPageX ] = useState(0);

  const HandleToggleTab = () => {
    if (![...ContentElement.current.classList].includes('show')) {
      ContentElement.current.classList.add('show');
      setTimeout(() => {
        HideElement1.current.classList.remove('hide');
        HideElement2.current.classList.remove('hide');
      }, 500)
    }
    else {
      ContentElement.current.classList.remove('show');
      HideElement1.current.classList.add('hide');
      HideElement2.current.classList.add('hide');
    }
  }

  const HandleMouseDown = (e) => {
    setPageX(e.pageX);
  }

  const HandleMouseMove = (e) => {
    if (pageX !== 0 && pageX - e.pageX > 100) {
      slider.current.next();
      setPageX(0);
    }
    else if (pageX !== 0 && pageX - e.pageX < -100) {
      slider.current.prev();
      setPageX(0);
    }
  }

  const HandleMouseUp = (e) => {
    setPageX(0);
  }

  return(
    <div id='feedback' className='feedback component-layout'>
      <div 
        className='title-box component-layout'
        onClick={HandleToggleTab}
      >
        <div className='component-wrapper title heading-2'>
          Khách hàng nói gì về Pippip
        </div>
      </div>
      <div 
        id='feedback-content-layer'
        ref={ContentElement} 
        className='component-layout feedback-content-layer'
      >
        <div 
          id='feedback-content'
          ref={HideElement1} 
          className='component-wrapper feedback-content hide'
        >
          <div 
            className='feedback-carousel'
            onMouseDown={HandleMouseDown}
            onMouseMove={HandleMouseMove}
            onMouseUp={HandleMouseUp}
          >
            <Carousel
              ref={slider}
              autoplay
              autoplaySpeed={3000}
              slidesToShow={1}
              touchMove={true}
              dots={false}
            >
              {FeedbackList.map((e, index) =>
                <FeedbackItem
                  key={index}
                  img={e.img}
                  name={e.name}
                  description={e.description}
                  feedback={e.feedback}
                />
              )}
            </Carousel>
          </div>
          <div className='feedback-slogan'>
            <div>
              <h1 className='heading-1'>Lấy khách hàng làm trung tâm</h1>
              <p className="heading-4">Pippip luôn tập trung vào việc tạo ra trải nghiệm tích cực cho khách hàng bằng cách tối đa hóa, đa dạng hoá chất lượng và số lượng dịch vụ, dựa trên phản hồi và nhu cầu thực tế của khách hàng.</p>
              <a href='/feedback' className='text-p-big'>XEM THÊM PHẢN HỒI</a>
            </div>
          </div>
        </div>
        <div 
          id='feedback-end-line' 
          ref={HideElement2} 
          className='line hide'
        />
      </div>
    </div>
  );
}

export default Feedback;