export const IntroContent = [
  {
    subtitle: 'Giá tốt',
    content1: 'Chúng tôi luôn tìm tới các nhà cung cấp là các xe chạy trực tiếp cho chuyến đi. Đồng thời tối ưu hệ thống vận hành bằng công nghệ tự động hoá.',
    img: '/Image/IntroIcon/money.png'
  },
  {
    subtitle: 'Chất lượng',
    content1: 'Áp dụng quy trình quản lý nhà cung cấp chặt chẽ thông qua việc theo dõi tự động.',
    content2: 'Áp dụng các giải thuật tối ưu để tự động điều xe phù hợp nhất cho khách hàng.',
    img: '/Image/IntroIcon/speed-copy.png'
  },
  {
    subtitle: 'Chăm sóc khách hàng',
    content1: 'Chúng tôi luôn lắng nghe mọi phản hồi của khách hàng về dịch vụ, về lái xe để liên tục cải tiến và đưa ra chính sách làm khách hàng hài lòng nhất',
    img: '/Image/IntroIcon/heart-icon.png'
  }
];