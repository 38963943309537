import Overview from './Overview/Overview';
import Introduction from './Introduction/Introduction';
import Service from './Service/Service';
import Feedback from './Feedback/Feedback';
import MobileLink from '../../Components/MobileLink/MobileLink';
import CarType from './CarType/CarType';

export default function Home() {
  return(
    <div id='home-content'>
      <Overview />
      <Introduction/>
      <Service/>
      <Feedback />
      <MobileLink />
      <CarType />
    </div>
  );
}