import { useEffect, useState } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import { getDocs, collection } from 'firebase/firestore';

import './Call.css'
// import { SaleList } from './SaleList';
import SaleItem from './SaleItem';
import MobileLink from '../../Components/MobileLink/MobileLink';

export default function Call() {
  const [ onlineList, setOnlineList ] = useState([]);
  const [ offlineList, setOfflineList ] = useState([]);

  useEffect(() => {
    const firebaseConfig = {
      apiKey: "AIzaSyD4q-7cxKIJYeC-6hybqSiw6Q3h1kuJ3ls",
      authDomain: "pippipinfo.firebaseapp.com",
      projectId: "pippipinfo",
      storageBucket: "pippipinfo.appspot.com",
      messagingSenderId: "77565113245",
      appId: "1:77565113245:web:e1ea6a4dafca1330bcb574",
      measurementId: "G-RT0Q6E621V"
    };
    const app = firebase.initializeApp(firebaseConfig);
    const db = app.firestore();
    getDocs(collection(db, "SaleList")).then((SaleList) => {
      // get current time
      const d = new Date();
      let now = d.getHours();
      let nowDay = d.getDay();
      let onlList = [], offList = [];
      
      // Loop all item in sale list
      SaleList.forEach((sale) => {
        let onlTime = false;
        let onlDay = false;

        // check online by hour
        sale.data().time.every((t) => {
          if (t.end > t.start) {
            if (now >= t.start && now < t.end) {
              onlTime = true;
              return false;
            }
            else return true;
          }
          else {
            if (now >= t.start || now < t.end) {
              onlTime = true;
              return false;
            }
            else return true;
          }
        });

        // check by weekdays if exist
        // Sunday --> nowDay = 0
        // Monday --> nowDay = 1
        // Tuesday --> nowDay = 2
        // ...
        // Saturday --> nowDay = 6
        if (sale.data().day) {
          sale.data().day.every((day) => {
            if (day.end > day.start) {
              if (nowDay >= day.start && nowDay <= day.end) {
                onlDay = true;
                return false;
              }
              else return true;
            }
            else {
              if (nowDay >= day.start || nowDay <= day.end) {
                onlDay = true;
                return false;
              }
              else return true;
            }
          });
        } else onlDay = true;

        if (onlTime && onlDay) { onlList.push(sale.data()); }
        else { offList.push(sale.data()); }
      })

      setOnlineList(onlList);
      setOfflineList(offList);
    });
  }, [])
  
  return(
    <>
      <div className='call'>
        <div className='call-title component-layout'>
          <div className="call-wrapper component-wrapper">
            <div className='page-path'>
              <a className='text-p-big' href='/'>Home</a>
              <span className='text-p-big'>{'>'}</span>
              <a className='text-p-big' href='/call'>Call</a>
            </div>
            <h1 className='call-title-text heading-1'>Liên hệ đặt xe</h1>
          </div>
        </div>
        <div className='call-online-title title-box component-layout'>
          <div className='component-wrapper title heading-2'>
            Trực tuyến
          </div>
        </div>
        <div className='component-layout'>
          <div className='sale-box component-wrapper'>
            {onlineList.map((e, index) =>
              <SaleItem key={index} name={e.name} phoneNumber={e.phoneNumber} />
            )}
          </div>
        </div>
        <div className='call-offline-title title-box component-layout'>
          <div className='component-wrapper title heading-2'>
            Ngoại tuyến
          </div>
        </div>
        <div className='component-layout'>
          <div className='offline-box sale-box component-wrapper'>
            {offlineList.map((e, index) =>
              <SaleItem 
                key={index} 
                name={e.name} 
                phoneNumber={e.phoneNumber}
                offline={true}
              />
            )}
          </div>
        </div>
      </div>
      <MobileLink />
    </>
  );
}