import { useState } from 'react';

import './CarType.css'
import { CarTypeList } from './CarTypeList';
import CarTypeItem from './CarTypeItem';
import CarDetail from './Cardetail';

function CarType() {
  const [ carType, setCarType ] = useState(0);
  const [ carList, setCarList ] = useState([]);
  const [ visible, setVisible ] = useState(false);

  const ShowDetail = (type, list) => {
    setCarType(type);
    setCarList(list);
    document.getElementById('car-detail').classList.remove('hide');
    setVisible(true);
  }

  return(
    <div className='car-type component-layout'>
      <div className='car-type-wrapper component-wrapper'>
        <h1 className='title heading-1'>Các dòng xe của Pippip</h1>
        <div className='car-type-4-16'>
          {CarTypeList[0].map((e, index) => 
            <CarTypeItem
              key={index}
              name={e.name}
              carList={e.carList}
              ShowDetail={ShowDetail}
            />
          )}
        </div>
        <div className='car-type-29-45'>
          {CarTypeList[1].map((e, index) => 
            <CarTypeItem
              key={index}
              name={e.name}
              carList={e.carList}
              ShowDetail={ShowDetail}
            />
          )}
        </div>
      </div>
      <CarDetail 
        type={carType} 
        list={carList} 
        visible={visible}
        setVisible={setVisible}
      />
    </div>
  );
}

export default CarType;