import { useEffect } from 'react';
// import 'antd/dist/reset.css'
import 'antd/dist/antd.min.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import './App.css';
import Header from './Components/Header/Header';
import Footer from './Components/Footer/Footer';
import Redirect from './Pages/Redirect';
import { PUBLIC_ROUTER } from './Router/router';


function App() {
  useEffect(() => {
    if (window.location.hash === '#mobile-link') {
      document.getElementById("mobile-link").scrollIntoView();
    }
    else if (window.location.hash === '#introduction') {
      document.getElementById("introduction").scrollIntoView();
      document.getElementById('intro-content-layer').classList.add('show');
      setTimeout(() => {
        document.getElementById('introduction-content').classList.remove('hide');
      }, 500);
    }
    else if (window.location.hash === '#service') {
      document.getElementById("service").scrollIntoView();
      document.getElementById('service-content-layer').classList.add('show');
      setTimeout(() => {
        document.getElementById('service-content').classList.remove('hide');
      }, 500);
    }
    else if (window.location.hash === '#feedback') {
      document.getElementById("feedback").scrollIntoView();
      document.getElementById('feedback-content-layer').classList.add('show');
      setTimeout(() => {
        document.getElementById('feedback-content').classList.remove('hide');
        document.getElementById('feedback-end-line').classList.remove('hide');
      }, 500);
    }
  })

  return (
    <div className="App">
      <Header />
      <BrowserRouter>
        <Routes>
          <Route
            path='*'
            element={<Redirect />}
          />
          {PUBLIC_ROUTER.map(e =>
            <Route
              key={e.key}
              path={e.path}
              element={e.element}
            />
          )}
        </Routes>
      </BrowserRouter>
      <Footer />
    </div>
  );
}

export default App;
