export const CarTypeList = [
  [
    {
      name: 'Xe 4, 5 chỗ',
      carList: [
        {
          name: 'Honda Civic',
          img: './Image/Cars/5Seats/5 hondacivic.png'
        },
        {
          name: 'Huyndai Accent',
          img: './Image/Cars/5Seats/5 huyndai accent.png'
        },
        {
          name: 'Mazda 3',
          img: './Image/Cars/5Seats/5 mazda.png'
        },
        {
          name: 'Toyota Vios',
          img: './Image/Cars/5Seats/5 toyota vios.png'
        }
      ]
    },
    {
      name: 'Xe 7 chỗ',
      carList: [
        {
          name: 'Innova Crysta',
          img: './Image/Cars/7Seats/7 innova crysta.png'
        },
        {
          name: 'Kia Carnival',
          img: './Image/Cars/7Seats/7 kia carnival.png'
        },
        {
          name: 'Kia Sedona',
          img: './Image/Cars/7Seats/7 kia sedona.png'
        },
        {
          name: 'Mitsubishi Xpander',
          img: './Image/Cars/7Seats/7 mitsubishi xpander_.png'
        },
        {
          name: 'Toyota Crimson',
          img: './Image/Cars/7Seats/7 toyota crimson spark.png'
        },
        {
          name: 'Toyota Fortuner',
          img: './Image/Cars/7Seats/7 toyota-fortuner.png'
        }
      ]
    },
    {
      name: 'Xe 16 chỗ',
      carList: [
        {
          name: 'Ford Transit',
          img: './Image/Cars/16Seats/16 ford transit.png'
        },
        {
          name: 'Huyndai Solati',
          img: './Image/Cars/16Seats/16 huyndai solati.png'
        },
        {
          name: 'Huyndai Solati trắng',
          img: './Image/Cars/16Seats/16 huyndai solati trắng.png'
        }
      ]
    }
  ],
  [
    {
      name: 'Xe 29 chỗ',
      carList: [
        {
          name: 'Huyndai County nâu',
          img: './Image/Cars/29Seats/29 hyundai_county_nau.png'
        },
        {
          name: 'Huyndai County vàng',
          img: './Image/Cars/29Seats/29 hyundai_county_vang.png'
        }
      ]
    },
    {
      name: 'Xe 35, 45 chỗ',
      carList: [
        {
          name: 'Huyndai Universe ghi',
          img: './Image/Cars/35_45Seats/45 huyndai universe.png'
        },
        {
          name: 'Huyndai Universe vàng',
          img: './Image/Cars/35_45Seats/45 huyndai universe vàng.png'
        },
        {
          name: 'Samco',
          img: './Image/Cars/35_45Seats/45 samco.png'
        }
      ]
    }
  ]
]