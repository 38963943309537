import { useRef } from 'react';

import './Service.css';
import { ServiceList } from './ServiceList'
import ServiceItem from './ServiceItem';

function Service() {
  const ContentElement = useRef(null);
  const HideElement = useRef(null);

  const HandleToggleTab = () => {
    if (![...ContentElement.current.classList].includes('show')) {
      ContentElement.current.classList.add('show');
      setTimeout(() => {
        HideElement.current.classList.remove('hide');
      }, 500)
    }
    else {
      ContentElement.current.classList.remove('show');
      HideElement.current.classList.add('hide');
    }
  }

  return(
    <div id='service' className='service component-layout'>
      <div 
        className='title-box component-layout'
        onClick={HandleToggleTab}
      >
        <div className='component-wrapper title heading-2'>
          Các dịch vụ của Pippip
        </div>
      </div>
      <div 
        id='service-content-layer'
        ref={ContentElement} 
        className='component-layout service-content-layer'
      >
        <div 
          id='service-content'
          ref={HideElement} 
          className='component-wrapper service-content hide'
        >
          <div id='service-content-big-screen'>
            {[...ServiceList[0], ...ServiceList[1]].map((e, index) =>
              <ServiceItem
                key={index}
                name={e.name}
                img={e.img}
              />
            )}
          </div>
          <div id='service-content-small-screen' >
            <div className='sub-service-content-1'>
              {ServiceList[0].map((e, index) =>
                <ServiceItem
                  key={index}
                  name={e.name}
                  img={e.img}
                />
              )}
            </div>
            <div className='sub-service-content-2'>
              {ServiceList[1].map((e, index) =>
                <ServiceItem
                  key={index}
                  name={e.name}
                  img={e.img}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Service;