import MemberItem from "./MemberItem";

export default function MemberBox({ boxId, boxClass, memberTeam, title }) {

  const HandleToggleTab = () => {
    document.getElementById(boxId)?.classList.toggle('hide');
  }

  return(
    <>
      <div
        className={`title-box ${boxClass} component-layout`}
        onClick={HandleToggleTab}
      >
        <div
          className='component-wrapper title heading-2'
        >
          {title}
        </div>
      </div>
      <div
        id={boxId}
        className='member-box component-layout hide'
      >
        <div
          className="member-box-wrapper component-wrapper"
        >
          {memberTeam.map((e, index) =>
            <MemberItem key={index} member={e} />
          )}
          {memberTeam.length === 1 ?
            <div className='insert-enough-480' /> : null
          }
          {memberTeam.length !== 0 && memberTeam.length < 3 ?
            <div className='insert-enough-900' /> : null
          }
        </div>
      </div>
    </>
  )
}