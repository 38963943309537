import './Service.css'

function ServiceItem(props) {
  return(
    <div 
      className={props.name ? 'service-item' : 'service-item hide'}
    >
      <img src={props.img} alt='' />
      <p className='heading-3'>{props.name}</p>
    </div>
  );
}

export default ServiceItem;