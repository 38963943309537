import { useState } from 'react';
import styled from 'styled-components';

import './Header.css';

const MenuItem = styled.a`
  text-decoration: none;
  color: white;
  font-size: 60px;
  width: max-content;
  margin-bottom: 20px;

  @media (min-width: 1600px) {
    font-size: 64px;
  }

  @media (min-width: 1600px) and (min-height: 1201px) {
    font-size: 68px;
  }

  @media (min-width: 1600px) and (min-height: 1401px) {
    font-size: 72px
  }

  @media (min-width: 1600px) and (min-height: 1601px) {
    font-size: 72px
  }

  @media (max-width: 768px) {
    font-size: 44px;
    font-weight: 400;
  }

  @media (max-width: 480px) {
    font-size: 25px;
    font-weight: 400;
  }
`

function Header() {
  const [ scroll, setScroll ] = useState(false);
  const [ y, setY ] = useState(0);

  window.onscroll = () => {
    const el = document.scrollingElement || document.documentElement;
    const top = el.scrollTop;
    const header = document.getElementById('header');

    if (top > 50 && !scroll) {
      setScroll(true);
      header.classList.add('scroll');
    }
    else if (top <= 50 && scroll) {
      setScroll(false);
      header.classList.remove('scroll');
    }

    if (!header.classList.contains("show")) {
      if (top > 100 && y < top) {
        header.style.top = '-100px';
      }
      else {
        header.style.top = '0px';
      }
    }

    setY(top);
  }

  const HandleOpenMenu = () => {
    document.querySelector('#header').classList.add('show');
    document.getElementById('home-content')?.classList?.add('hide');
  }

  const HandleCloseMenu = () => {
    document.querySelector('#header').classList.remove('show');
    document.getElementById('home-content')?.classList?.remove('hide');
  }

  return(
    <div 
      id='header' 
      className='header-layer component-layout'
    >
      <div className='header-wrapper component-wrapper'>
        <div 
          className='menu-layer'
        >
          <div className="sub-menu-layer component-layout">
            <div className='menu-box component-wrapper'>
              <MenuItem
                href='/'
                onClick={HandleCloseMenu}
              >
                Về Pippip
                <div className='menu-item-underline'/>
              </MenuItem>
              <MenuItem
                href='https://docs.google.com/document/d/1o2vT3psHEXMvtx_vgyOrvP0UcuxMJVDFrSf5U1PDvVY'
                target='_blank'
                rel="noreferrer"
              >
                Chính sách tuyển dụng lái xe
                <div className='menu-item-underline'/>
              </MenuItem>
              <MenuItem
                href='https://docs.google.com/document/d/1m201PmIjYD5X-OaCN1ce3M7yevL1A8Xu47i6Zs07W-M'
                target='_blank'
                rel="noreferrer"
              >
                Điều khoản sử dụng dịch vụ
                <div className='menu-item-underline'/>
              </MenuItem>
              <MenuItem
                href='https://docs.google.com/document/d/12_x1PTXsrZlJt5h83NeVOyIk7zs49moXX4Jge4lj-2Q'
                target='_blank'
                rel="noreferrer"
              >
                Điều khoản nhà cung cấp
                <div className='menu-item-underline'/>
              </MenuItem>
              <MenuItem
                href='/#mobile-link'
                onClick={HandleCloseMenu}
              >
                Đặt xe ngay
                <div className='menu-item-underline'/>
              </MenuItem>
            </div>
            {/* <div onClick={HandleCloseMenu} className='button-X'></div> */}
          </div>
        </div>
        <img 
          id='open-menu' 
          className='button-menu' 
          onClick={HandleOpenMenu}
          src='/Image/Menu_icon.png' alt='' 
        />
        <div
          id='hide-menu'
          className='hide button-menu button-X '
          onClick={HandleCloseMenu}
        />
        <a href='/'>
          <img 
            id='menu-logo-1'
            className='menu-logo'
            src='/Image/logo-pippip.png' 
            alt=''
          />
          <img 
            id='menu-logo-2'
            className='menu-logo'
            src='/Image/logo-pippip-2.png' 
            alt=''
          />
        </a>
      </div>
    </div>
  );
}

export default Header;