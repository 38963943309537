import { Image } from "antd";

function CarDetail(props) {
  const HidePreview = (vis) => {
    document.getElementById('car-detail').classList.add('hide');
    props.setVisible(vis);
  }


  return(
    <div id="car-detail" className="car-detail hide">
      <div className="type-name heading-3">{props.type}</div>
      <div style={{ display: 'none' }}>
        <Image.PreviewGroup 
          preview={{ 
            visible: props.visible,
            onVisibleChange: (vis) => HidePreview(vis)
          }}
        >
          {props.list.map((e, index) => 
            <Image key={index} src={e.img} />
          )}
        </Image.PreviewGroup>
      </div>
    </div>
  );
}

export default CarDetail;