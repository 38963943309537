import './Feedback.css'

function FeedbackItem(props) {
  return(
    <div className='feedback-item'>
      <div className='customer-info'>
        <img src={props.img} alt=''/>
        <div>
          <h1 className='heading-3'>{props.name}</h1>
          <p className='text-p'>{props.description}</p>
        </div>
      </div>
      <div className="feedback-item-content text-p">
        {props.feedback.split('\n').map((e, index) =>
          <p key={index} className='text-p'>{e}</p>
        )}
      </div>
    </div>
  );
}

export default FeedbackItem;