import { useRef, useState } from 'react';
import { Carousel } from 'antd';

import './CarType.css'

function CarTypeItem(props) {
  const [ pageX, setPageX ] = useState(0);
  const slider = useRef(null);

  const HandleMouseDown = (e) => {
    setPageX(e.pageX);
  }

  const HandleMouseMove = (e) => {
    if (pageX !== 0 && pageX - e.pageX > 100) {
      slider.current.next();
      setPageX(0);
    }
    else if (pageX !== 0 && pageX - e.pageX < -100) {
      slider.current.prev();
      setPageX(0);
    }
  }

  const HandleMouseUp = (e) => {
    setPageX(0);
  }

  return(
    <div 
      className='car-item' 
      onClick={() => props.ShowDetail(props.name, props.carList)}
    >
      <div 
        className='car-item-carousel'
        onMouseDown={HandleMouseDown}
        onMouseMove={HandleMouseMove}
        onMouseUp={HandleMouseUp}
      >
        <Carousel
          ref={slider}
          autoplay
          autoplaySpeed={3000}
          slidesToShow={1}
          touchMove={true}
          dots={false}
        >
          {props.carList.map((e, index) =>
            <div className='car-image' key={index}>
              <img src={e.img} alt='' />
            </div>
          )}
          {/* {console.log(props.carList)} */}
        </Carousel>
      </div>
      <p className='heading-4'>{props.name}</p>
    </div>
  );
}

export default CarTypeItem;