import './Overview.css'

function Overview() {
  return(
    <div className='overview component-layout'>
      <div className='overview-wrapper component-wrapper'>
        <div className='overview-content'>
          <div className='slogan'>
            <h1>PIPPIP.VN</h1>
            <p>Nền tảng đặt xe sân bay,</p>
            <p>du lịch nghỉ dưỡng trọn gói</p>
            <div className='button-box'>
              <a href='#mobile-link' className='download-app'>Tải ứng dụng</a>
              <a href='/call' className='contact'>Liên hệ</a>
            </div>
          </div>
          <img alt='' src='./Image/Overview.png' />
        </div>
        <div className='car-logo-list'>
          <img alt='' src='./Image/Car_logo/car_logo_1.png' />
          <img alt='' src='./Image/Car_logo/car_logo_2.png' />
          <img alt='' src='./Image/Car_logo/car_logo_3.png' />
          <img alt='' src='./Image/Car_logo/car_logo_4.png' />
          <img alt='' src='./Image/Car_logo/car_logo_5.png' />
          <img alt='' src='./Image/Car_logo/car_logo_6.png' />
          <img alt='' src='./Image/Car_logo/car_logo_7.png' />
          <img alt='' src='./Image/Car_logo/car_logo_8.png' />
        </div>
      </div>
    </div>
  );
}

export default Overview;