import './AboutUs.css';

export default function MemberItem({ member }) {
  return(
    <div className="member-team-item">
      <div className='img-aspect-ratio-wrapper'>
        <img src={member.photoURL} alt=''/>
      </div>
      <p className='member-name heading-3'>{member.name}</p>
      {member.description ?
        <p className='member-description text-p-big'>{member.description}</p>
        :
        null
      }
      
    </div>
  )
}