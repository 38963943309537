import firebase from 'firebase/compat/app';
// import { getStorage, ref, listAll } from 'firebase/storage';
import 'firebase/compat/storage';
import { Select, Image, Space } from 'antd';
import { EyeOutlined } from '@ant-design/icons'
import { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import './Feedback.css';

// const allData = [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30];

export default function Feedback() {
  const [ allData, setAllData ] = useState([]);
  const [ data, setData ] = useState([]);
  const [ loading, setLoading ] = useState(false);
  const [ year, setYear ] = useState(2023);
  const [ quarter, setQuarter ] = useState(2);

  useEffect(() => {
    setAllData([]);
    const firebaseConfig = {
      apiKey: "AIzaSyD4q-7cxKIJYeC-6hybqSiw6Q3h1kuJ3ls",
      authDomain: "pippipinfo.firebaseapp.com",
      projectId: "pippipinfo",
      storageBucket: "pippipinfo.appspot.com",
      messagingSenderId: "77565113245",
      appId: "1:77565113245:web:e1ea6a4dafca1330bcb574",
      measurementId: "G-RT0Q6E621V"
    };
  
    const app = firebase.initializeApp(firebaseConfig);
    const image = app.storage().ref(`${year}/Quy${quarter}`);
    // const image = app.storage().ref(`testFolder`);

    image.listAll().then(async (result) => {
      setAllData([]);
      result.items.forEach(async (item) => {
        item.getDownloadURL().then((url) => {
          // console.log(i++, ': ', url);
          setAllData(prev => [...prev, url]);
        })
      });
    }).catch((error) => {
      console.log(error)
    });

    // console.log(allData);
  }, [quarter, year]);

  useEffect(() => {
    // console.log('abc: ', allData);
    setData(allData.slice(0, 10));
  }, [allData, quarter, year]);

  const HandleChangeYear = (value) => {
    setYear(value);
    // window.location.reload();
  }

  const HandleChangeQuarter = (value) => {
    setQuarter(value);
    // window.location.reload();
  }

  const Loadmore = () => {
    if (loading) return;

    setLoading(true);
    let length = data.length;
    setData([...data, ...allData.slice(length, length + 10)]);
    setLoading(false);
  }

  return(
    <div className='feedback-page component-layout'>
      <div className="feedback-page-wrapper component-wrapper">
        <div className='page-path'>
          <a className='text-p-big' href='/'>Home</a>
          <span className='text-p-big'>{'>'}</span>
          <a className='text-p-big' href='/feedback'>Feedback</a>
        </div>
        <h1 className='feedback-page-title-text heading-1'>Feedback</h1>
        <div className='selection-box'>
          <Select 
            className='select-year'
            defaultValue={(new Date()).getFullYear()}
            options={[
              { value: 2025, label: '2025' },
              { value: 2024, label: '2024' },
              { value: 2023, label: '2023' },
              { value: 2022, label: '2022' },
              { value: 2021, label: '2021' },
              { value: 2020, label: '2020' },
            ]}
            onChange={HandleChangeYear}
          />
          <Select 
            className='select-quarter'
            defaultValue={parseInt((new Date()).getMonth()/4) + 1}
            options={[
              { value: 1, label: 'Quý I' },
              { value: 2, label: 'Quý II' },
              { value: 3, label: 'Quý III' },
              { value: 4, label: 'Quý IV' },
            ]}
            onChange={HandleChangeQuarter}
          />
        </div>
        <div className="feedback-image">
          {data?.length ? null :
            <div className="text-p-big">
              Chưa có feedback trong khoảng thời gian này
            </div>
          }
          <InfiniteScroll
            dataLength={data.length}
            hasMore={data.length < allData.length}
            next={Loadmore}
            className='infinite-scroll-box'
          >
            <Image.PreviewGroup>
              {data.map((e, index) =>
                <Image 
                  key={index} 
                  src={e}
                  preview={{
                    maskClassName: 'customize-mask',
                    mask: (
                      <Space direction="horizontal" align="center">
                        <EyeOutlined />
                        Xem ảnh
                      </Space>
                    ),
                  }}
                />
              )}
            </Image.PreviewGroup>
            {data?.length === 1 ? <div className='insert-enough-480'/> : null}
            {data?.length !== 0 && data?.length < 3 ? 
            <div className='insert-enough-650'/> : null}
            {data?.length !== 0 && data?.length < 4 ? 
            <div className='insert-enough-768'/> : null}
            {data?.length !== 0 && data?.length < 5 ? 
            <div className='insert-enough-1024'/> : null}
            {data?.length !== 0 && data?.length < 6 ? 
            <div className='insert-enough-1170'/> : null}
          </InfiniteScroll>
        </div>
      </div>
    </div>
  );
}