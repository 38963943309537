import { useRef } from 'react';

import './Introduction.css';
import IntroItem from './IntroItem';
import { IntroContent } from './IntroContent'

function Introduction() {
  const ContentElement = useRef(null);
  const HideElement = useRef(null);

  const HandleToggleTab = () => {
    if (![...ContentElement.current.classList].includes('show')) {
      ContentElement.current.classList.add('show');
      setTimeout(() => {
        HideElement.current.classList.remove('hide');
      }, 500)
    }
    else {
      ContentElement.current.classList.remove('show');
      HideElement.current.classList.add('hide');
    }
  }

  return(
    <div id='introduction' className='introduction component-layout'>
      <div 
        className='title-box component-layout'
        onClick={HandleToggleTab}
      >
        <div className='component-wrapper title heading-2'>
          Tại sao nên đặt xe Pippip
        </div>
      </div>
      <div 
        id='intro-content-layer'
        ref={ContentElement} 
        className='component-layout intro-content-layer'
      >
        <div
          id='introduction-content'
          ref={HideElement} 
          className='component-wrapper introduction-content hide'
        >
          <div className='img' >
            <img src='/Image/Car-introduction.png' alt='' />
          </div>
          <div className='main-intro-content'>
            {IntroContent.map((e, index) =>
              <IntroItem
                key={index}
                subtitle={e.subtitle}
                content1={e.content1}
                content2={e.content2}
                img={e.img}
              />
            )}
            <a href='/feedback' className='text-p-big'>Xem phản hồi của khách hàng</a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Introduction;