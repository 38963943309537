import Home from "../Pages/Home/Home";
import Call from "../Pages/Call/Call";
import Feedback from "../Pages/Feedback/Feedback";
import AboutUs from "../Pages/AboutUs/AboutUs";

export const PUBLIC_ROUTER = [
  {
    key: "home",
    path: '/',
    element: <Home/>,
  },
  {
    key: 'call',
    path: '/call',
    element: <Call />,
  },
  {
    key: 'feedback',
    path: '/feedback',
    element: <Feedback />,
  },
  {
    key: 'about-us',
    path: '/about-us',
    element: <AboutUs />,
  }
]