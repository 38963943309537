export const ServiceList = [
  [
    {
      name: 'Xe sân bay',
      img: './Image/Service_icon/Xe-san-bay.png'
    },
    {
      name: 'Xe du lịch',
      img: './Image/Service_icon/Xe-du-lich.png'
    },
    {
      name: 'Thuê giờ',
      img: './Image/Service_icon/Thue-gio.png'
    }
  ],
  [
    {
      name: 'Xe đi tỉnh',
      img: './Image/Service_icon/Xe-di-tinh.png'
    },
    {
      name: 'Xe taxi',
      img: './Image/Service_icon/Xe-taxi.png'
    },
    {
      name: '',
      img: './Image/Service_icon/blank.png'
    }
  ]
];